import {
  IPaxComplects,
  IPackageProductDetailsClass,
  IProductDetailsPaxComplectClass
} from 'modules/product-result.v2/data/product-result.types'

import {PaxComplectResource, PaxComplectsResource} from 'be-structures/typescript-generator';

import {
  setClassName
} from 'modules/common/common-methods/common-methods'

import {
  PAX_COMPLECTS_BLANK
} from 'modules/product-result.v2/data/package-result/package-result.product-details/package-result.product-details.consts'

import {ProductDetailsPaxComplect} from 'modules/product-result.v2/data/package-result/package-result.details-complect/package-result.details-complect.class'

export default class PackageProductDetails
  implements IPackageProductDetailsClass {
  private _details: PaxComplectsResource = null
  private _complects: IProductDetailsPaxComplectClass[] = []
  private _complectsIds: string[] = []

  constructor(details?: PaxComplectsResource) {
    setClassName(PackageProductDetails.name, this)

    this._details = details || PAX_COMPLECTS_BLANK

    this._complects = this._details.complects.map((complect: PaxComplectResource) => {
      this._complectsIds.push(complect.entityId)
      return new ProductDetailsPaxComplect(complect)
    })
  }

  get content(): IPackageProductDetailsClass['content'] {
    return this._details
  }
  get complectsIds(): string[] {
    return this._complectsIds
  }
  get paxComplectsResource(): IProductDetailsPaxComplectClass[] {
    return this._complects
  }
  get remarkList() {
    return this._details.remarkList
  }
  get remarks() {
    return this._details.remarks
  }
  get additionalPayments() {
    return this._details.additionalPayments
  }

  set paxComplectsResource(value: IProductDetailsPaxComplectClass[]) {
    this._complects = value
  }

  getPaxComplectsIds() {
    return this._complects.map(complect => complect.content.entityId)
  } 
}
