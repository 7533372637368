import {
  PAX_COMPLECT_BLANK,
  PRODUCT_PRICE_BLANK
} from 'modules/product-result.v2/data/product-result.types';
import {PaxComplectsResource} from "be-structures/typescript-generator/assembly";

export const PAX_COMPLECTS_BLANK: PaxComplectsResource = {
  additionalPayments: [],
  remarks: null,
  taxes: null,
  remarkList: [],
  dealFeatures: [],
  id: null,
  complects: []
}