import {
  ITravelProductPriceResource,
} from 'modules/product-result.v2/data/product-result.types';

import {
  PRICE_BLANK
} from 'modules/common/common.types'
import {PriceResource} from "be-structures/typescript-generator";

export const PRODUCT_PRICE_BLANK: PriceResource = {
  ...PRICE_BLANK,
  paxPrices: [],
  markup: null,
  commission: null
}