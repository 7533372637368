import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

import {
  IProductDetailsPaxComplectClass,
  ICustomerClass,
  TRoomsCustomer,
  IPackageProductDetailsClass,
  PackageProductDetails,
  Customer,
  TRoomsAdditions,
  TOrderPendings,
  OrderPendingsNames
} from 'applications/desktop/package-compare-app/components/order/types/order.types';
import {Store} from "vuex";

export const PRODUCT_ORDER_MODULE_VIEW_NAME = 'PRODUCT_ORDER_MODULE_VIEW'

export const PRODUCT_ORDER_MODULE_VIEW = (http: any, store: Store<any>) => {
  @Module({ name: PRODUCT_ORDER_MODULE_VIEW_NAME, namespaced: true, store: store })
  class ProductOrderViewModule extends VuexModule {
    private _orderStepNumber: number = 1
    private _boarding: string = null
    private _productDetails: IPackageProductDetailsClass = new PackageProductDetails()
    private _owner: ICustomerClass = new Customer()
    private _customers: TRoomsCustomer = null
    private _additions: TRoomsAdditions = null
    private _formsIds: string[] = []
    private _keyOfCustomerAsOwner: string = null
    private _pendings: TOrderPendings = {
      startOrderReservation: false
    }


    get pendings() {
      return this._pendings
    }

    get orderStepNumber() {
      return this._orderStepNumber
    }

    get boarding() {
      return this._boarding
    }

    get productDetails() {
      return this._productDetails
    }

    get owner() {
      return this._owner
    }

    get customers() {
      return this._customers
    }
    
    get additions() {
      return this._additions
    }

    get formsIds() {
      return this._formsIds
    }

    get keyOfCustomerAsOwner() {
      return this._keyOfCustomerAsOwner
    }


    @Mutation
    resetStore() {
      this._orderStepNumber = 1
      this._boarding = null
      this._productDetails = new PackageProductDetails()
      this._owner = new Customer()
      this._customers = null
      this._additions = null
      this._formsIds = []
      this._pendings = {
        startOrderReservation: false
      }
    }

    @Mutation
    setPending({ pendingName, value }: { pendingName: keyof typeof OrderPendingsNames, value: boolean }) {
      this._pendings[pendingName] = value
    }

    @Mutation
    setOrderStep(stepNumber: number) {
      this._orderStepNumber = stepNumber
    }
    
    @Mutation
    selectBoarding(boarding: string) {
      this._boarding = boarding
    }

    @Mutation
    setProductDetails(details: IPackageProductDetailsClass) {
      this._productDetails = details
    }

    @Mutation
    selectPaxComplects(paxComplectsResource: IProductDetailsPaxComplectClass[]) {
      if(!paxComplectsResource) return
      this._productDetails.paxComplectsResource = paxComplectsResource
    }

    @Mutation
    setOwner(owner: ICustomerClass) {
      this._owner = owner
    }

    @Mutation
    setCustomers(customers: TRoomsCustomer) {
      this._customers = customers
    }

    @Mutation
    clearOwner() {
      this._owner = new Customer()
    }


    @Mutation
    selectAdditions(additions: TRoomsAdditions) {
      this._additions = additions
    }

    @Mutation
    setFormsIds(formsIds: string[]) {
      this._formsIds = formsIds
    }

    @Mutation
    changeServiceQuantity({
      roomGroupKey,
      customerKey,
      serviceId,
      quantity
    }: {
      roomGroupKey: string,
      customerKey: string,
      serviceId: string,
      quantity: number
    }) {
      this._additions[roomGroupKey][customerKey].additionalPayments.find(ap => ap.id === serviceId).quantity = quantity
    }

    @Mutation
    setKeyOfCustomerAsOwner(customerKey: string) {
      this._keyOfCustomerAsOwner = customerKey
    }
  }

  return ProductOrderViewModule
}
