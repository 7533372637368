import {
  TravelProductPriceResource
} from 'be-structures/typescript-generator/assembly';

import { Price } from 'modules/common/price/price.class'

import {
  IPackageProductPriceClass,
  ITravelProductPriceResource
} from 'modules/product-result.v2/data/package-result/package-result.product-price/package-result.product-price.types';

import {
  convertTravelProductPriceResourceToITravelProductPriceResource
} from 'modules/product-result.v2/data/package-result/package-result.product-price/package-result.product-price.converter'

import {
  setClassName
} from 'modules/common/common-methods/common-methods'
import {PriceResource} from "be-structures/typescript-generator";

export class PackageProductPrice extends Price implements IPackageProductPriceClass {
  private _productPrice: ITravelProductPriceResource = null

  constructor(productPrice?: PriceResource) {
    super(productPrice)
    
    setClassName(PackageProductPrice.name, this)

    this._productPrice = convertTravelProductPriceResourceToITravelProductPriceResource({ productPrice })
  }

  get content() {
    return this._productPrice
  }

  get paxPrices() {
    return this._productPrice.paxPrices
  }
}
