import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import VuexPersistence from 'vuex-persist'

import { i18n_NAME, i18n } from 'modules/i18n/i18n.store'
import PaymentStore from "../../../modules/payment/payment.store";
import paymentApi from "../../../modules/payment/payment.api";

Vue.use(Vuex)

export default (VuexPersistenceModules) => {
  const vuexLocal = new VuexPersistence<any>({
    storage: window.localStorage,
    reducer: (state) => VuexPersistenceModules(state)
  })

  const storeConfig: StoreOptions<any> = {
    modules: {
      [i18n_NAME]: i18n(),
    },
    plugins: [vuexLocal.plugin]
  }

  return new Vuex.Store<any>(storeConfig)
}